import React, { useState } from 'react';
import MapComponent from './MapComponent';

const App = () => {
  const [startCoords, setStartCoords] = useState([]);
  const [endCoords, setEndCoords] = useState([]);
  const [showRoute, setShowRoute] = useState(false);
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [routeInfo, setRouteInfo] = useState(null);

  const handleStartCoordChange = (e) => {
    const [lat, lng] = e.target.value.split(',').map(parseFloat);
    setStartCoords([lat, lng]);
  };

  const handleEndCoordChange = (e) => {
    const [lat, lng] = e.target.value.split(',').map(parseFloat);
    setEndCoords([lat, lng]);
  };

  const handleShowMarkers = () => {
    if (startCoords.length && endCoords.length) {
      setShowRoute(true);
      setIsPreviewActive(false);
    }
  };

  const handlePreviewToggle = () => {
    setIsPreviewActive(!isPreviewActive);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col" style={{ backgroundColor: '#2a1617' }}>
      <nav className="bg-gray-800 p-4 flex flex-col md:flex-row md:justify-between md:items-center">
        <div className="text-2xl mb-4 md:mb-0 md:mr-4">LetsGo</div>
        <div className="flex flex-col md:flex-row">
          <input
            type="text"
            placeholder="Start Coordinates (lat,lng)"
            onChange={handleStartCoordChange}
            className="p-2 border border-gray-700 rounded mb-2 md:mb-0 md:mr-2 bg-gray-700 text-white"
          />
          <input
            type="text"
            placeholder="End Coordinates (lat,lng)"
            onChange={handleEndCoordChange}
            className="p-2 border border-gray-700 rounded mb-2 md:mb-0 md:mr-2 bg-gray-700 text-white"
          />
          <button
            onClick={handleShowMarkers}
            className="p-2 bg-[#eb3e1e] text-white rounded mb-2 md:mb-0 md:mr-2"
          >
            Show Route
          </button>
          <button
            onClick={handlePreviewToggle}
            className="p-2 bg-blue-500 text-white rounded"
            disabled={!showRoute}
          >
            {isPreviewActive ? 'Stop Preview' : 'Preview'}
          </button>
        </div>
      </nav>
      <div className="flex-grow w-full h-96">
        <MapComponent
          startCoords={showRoute ? startCoords : []}
          endCoords={showRoute ? endCoords : []}
          isPreviewActive={isPreviewActive}
          onRouteInfoUpdate={setRouteInfo}
          onPreviewComplete={() => setIsPreviewActive(false)}
        />
      </div>
      <footer className="bg-gray-800 p-4 flex justify-between items-center">
        <div>
          {routeInfo && (
            <span>
              Distance: {routeInfo.distance} km | 
              Duration: {routeInfo.duration.hours}h {routeInfo.duration.minutes}m
            </span>
          )}
        </div>
        <div>&copy; 2024 LetsGo</div>
      </footer>
    </div>
  );
};

export default App;