import React, { useRef, useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, useMap, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';

const stpointIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const edpointIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const movingIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const FitBounds = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);
  return null;
};

const RoutingMachine = ({ startCoords, endCoords, onRouteFound }) => {
  const map = useMap();
  const routingControlRef = useRef(null);

  useEffect(() => {
    if (!startCoords.length || !endCoords.length) return;

    if (routingControlRef.current) {
      map.removeControl(routingControlRef.current);
    }

    routingControlRef.current = L.Routing.control({
      waypoints: [
        L.latLng(startCoords[0], startCoords[1]),
        L.latLng(endCoords[0], endCoords[1])
      ],
      lineOptions: {
        styles: [{ color: '#eb3e1e', weight: 4 }]
      },
      createMarker: () => null,
      addWaypoints: false,
      routeWhileDragging: false,
      draggableWaypoints: false,
      show: false,
      showAlternatives: false,
      fitSelectedRoutes: false
    }).addTo(map);

    const container = routingControlRef.current.getContainer();
    container.style.display = 'none';

    routingControlRef.current.on('routesfound', (e) => {
      const routes = e.routes;
      const summary = routes[0].summary;
      const distance = (summary.totalDistance / 1000).toFixed(2);
      const hours = Math.floor(summary.totalTime / 3600);
      const minutes = Math.round((summary.totalTime % 3600) / 60);
      
      onRouteFound({
        turningPoints: routes[0].coordinates,
        distance,
        duration: { hours, minutes }
      });
    });

    return () => {
      if (routingControlRef.current && map) {
        map.removeControl(routingControlRef.current);
      }
    };
  }, [map, startCoords, endCoords, onRouteFound]);

  return null;
};

const MapComponent = ({ startCoords, endCoords, isPreviewActive, onRouteInfoUpdate, onPreviewComplete }) => {
  const mapRef = useRef(null);
  const [turningPoints, setTurningPoints] = useState([]);
  const [previewIndex, setPreviewIndex] = useState(-1);
  const animationRef = useRef(null);

  const bounds = startCoords.length && endCoords.length 
    ? L.latLngBounds([startCoords, endCoords]) 
    : null;

  const handleRouteFound = useCallback(({ turningPoints, distance, duration }) => {
    setTurningPoints(turningPoints);
    onRouteInfoUpdate({ distance, duration });
  }, [onRouteInfoUpdate]);

  const previewNextPoint = useCallback(() => {
    setPreviewIndex(prevIndex => {
      if (prevIndex < turningPoints.length - 1) {
        // Set the time interval for moving to the next point (200ms)
        animationRef.current = setTimeout(previewNextPoint, 200);
        return prevIndex + 1;
      }
      onPreviewComplete(); // Call this when the preview is complete
      return prevIndex;
    });
  }, [turningPoints, onPreviewComplete]);

  useEffect(() => {
    if (isPreviewActive) {
      setPreviewIndex(0);
      previewNextPoint();
    } else {
      setPreviewIndex(-1);
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    }

    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, [isPreviewActive, previewNextPoint]);

  return (
    <div className="h-full relative">
      <MapContainer
        center={[0, 0]}
        zoom={2}
        style={{ height: '100%' }}
        className="h-full"
        whenCreated={(map) => {
          mapRef.current = map;
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        />
        {startCoords.length > 0 && <Marker position={startCoords} icon={stpointIcon} />}
        {endCoords.length > 0 && <Marker position={endCoords} icon={edpointIcon} />}
        {startCoords.length > 0 && endCoords.length > 0 && (
          <RoutingMachine 
            startCoords={startCoords} 
            endCoords={endCoords} 
            onRouteFound={handleRouteFound}
          />
        )}
        {bounds && <FitBounds bounds={bounds} />}
        {previewIndex >= 0 && previewIndex < turningPoints.length && (
          <Marker key={previewIndex} position={turningPoints[previewIndex]} icon={movingIcon} />
        )}
        {turningPoints.length > 0 && (
          <Polyline 
            positions={turningPoints.slice(0, previewIndex + 1)} 
            color="green" 
            weight={4} 
          />
        )}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
